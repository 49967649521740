<template>
  <div>
    <v-row>
      <h3 class="mt-4 mx-4">
        Fill in the data required to generate a contract for this employee
      </h3>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-textarea
          class="mx-4"
          outlined
          label="What is your work address"
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6">
        <div>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mx-4"
                v-model="date"
                outlined
                label="Contract Start Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              :active-picker.sync="activePicker"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
              min="1950-01-01"
              @change="save"
            ></v-date-picker>
          </v-menu>
          <v-col cols="12" md="6">
            <v-text-field
              class="mx-4"
              outlined
              label="Termination notice period"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              class="mx-4"
              outlined
              label="Job Title"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-textarea
              class="mx-4"
              outlined
              label="Job Responsibilities"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              class="mx-4"
              outlined
              label="Working hours"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              class="mx-4"
              outlined
              label="Lunch period"
            ></v-text-field>
          </v-col>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { gql } from "apollo-boost";

export default {
  components: {},
  name: "Contracts",
  data() {
    return {
      activePicker: null,
      date: null,
      menu: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>

<style lang="scss" scoped></style>
